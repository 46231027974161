import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/Recomendacao/styled"

const RecomendacaoPage = () => {
  const {
    imgFNO,
    imgViking,
    imgAvenger,
    imgUmbler,
    imgRgpd,
    imgGpa,
  } = useStaticQuery(graphql`
    query {
      imgFNO: file(
        relativePath: {
          eq: "recomendacoes/voce-empreendedor-digital-formula-negocio-online.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgViking: file(
        relativePath: {
          eq: "recomendacoes/voce-empreendedor-digital-viking.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgGpa: file(
        relativePath: {
          eq: "recomendacoes/voce-empreendedor-digital-gpa-caio-calderaro.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgAvenger: file(
        relativePath: {
          eq: "recomendacoes/voce-empreendedor-digital-avenger.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgUmbler: file(
        relativePath: {
          eq: "recomendacoes/voce-empreendedor-digital-hospedagem-umbler.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgRgpd: file(
        relativePath: {
          eq: "recomendacoes/voce-empreendedor-digital-rgpd-pro.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout visibleSideBar={true}>
      <SEO
        title="Ferramentas que recomendo"
        description="Aqui você irá encontrar os melhores cursos, ferramentas e materiais que uso e recomendo para o seu dia a dia."
      />
      <S.RecomendacaoWrapper>
        <S.RecomendacaoHeader>
          <S.RecomendacaoTitle>Recomendação</S.RecomendacaoTitle>
          <S.RecomendacaoDescription>
            Aqui abaixo vou listar para vocês todos os cursos e as ferramentas
            que utilizo e faço uso no meu dia a dia que tem ajudado a ter a
            minha estrutura de forma mais profissional.
          </S.RecomendacaoDescription>
        </S.RecomendacaoHeader>
        <S.RecomendacaoBox
          href="http://bit.ly/ved-curso-completo-recomendacao"
          target="_blank"
          rel="noopener noreferrer"
        >
          <S.RecomendacaoImg
            fluid={imgFNO.childImageSharp.fluid}
            alt="Imagem do Alex Vargas com o título Fórmula Negócio Online"
          />
          <S.RecomendacaoTextBox>
            <S.RecomendacaoSubtitle>
              Fórmula Negócio Online
            </S.RecomendacaoSubtitle>
            <S.RecomendacaoText>
              Nesse curso o Alex Vargas vai pegar desde o ínicio de um negócio
              digital até você montar uma estrutura completa, do ínicio ao fim.
            </S.RecomendacaoText>
            <S.RecomendacaoFakeButton>Saiba Mais</S.RecomendacaoFakeButton>
          </S.RecomendacaoTextBox>
        </S.RecomendacaoBox>
        <S.RecomendacaoBox
          href="http://bit.ly/ved-viking"
          target="_blank"
          rel="noopener noreferrer"
        >
          <S.RecomendacaoImg
            fluid={imgViking.childImageSharp.fluid}
            alt="Desenho da imagem da cabeça de um viking a frente de um escudo, embaixo o título Afiliado Viking"
          />
          <S.RecomendacaoTextBox>
            <S.RecomendacaoSubtitle>Afiliado Viking</S.RecomendacaoSubtitle>
            <S.RecomendacaoText>
              O Afiliado Viking é um curso focado em tráfego pago, onde você vai
              aprender na prática como conseguir mais tráfego para o seu
              negócio.
            </S.RecomendacaoText>
            <S.RecomendacaoFakeButton>Saiba Mais</S.RecomendacaoFakeButton>
          </S.RecomendacaoTextBox>
        </S.RecomendacaoBox>
        <S.RecomendacaoBox
          href="http://bit.ly/gpa-ved-recomendacao"
          target="_blank"
          rel="noopener noreferrer"
        >
          <S.RecomendacaoImg
            fluid={imgGpa.childImageSharp.fluid}
            alt="Imagem com o Caio Calderaro, produtor do curso GPA - Domínio Estratégico"
          />
          <S.RecomendacaoTextBox>
            <S.RecomendacaoSubtitle>
              GPA - Domínio Estratégico
            </S.RecomendacaoSubtitle>
            <S.RecomendacaoText>
              Se você quer iniciar no Google Ads e ter bastante resultado, esse
              com certeza é o melhor curso para iniciar no mundo google.
            </S.RecomendacaoText>
            <S.RecomendacaoFakeButton>Saiba Mais</S.RecomendacaoFakeButton>
          </S.RecomendacaoTextBox>
        </S.RecomendacaoBox>
        <S.RecomendacaoBox
          href="https://bit.ly/ved-hospedagem-teste-gratis"
          target="_blank"
          rel="noopener noreferrer"
        >
          <S.RecomendacaoImg
            fluid={imgUmbler.childImageSharp.fluid}
            alt="Imagem da Hospedagem Umbler com teste grátis de 7 dias"
          />
          <S.RecomendacaoTextBox>
            <S.RecomendacaoSubtitle>
              Hospedagem com 7 dias de teste grátis
            </S.RecomendacaoSubtitle>
            <S.RecomendacaoText>
              Ótima hospedagem para começar sua própria estrutura, essa é a
              melhor escolha. Garanta seus 7 dias de teste grátis com esse link.
            </S.RecomendacaoText>
            <S.RecomendacaoFakeButton>Garanta Já</S.RecomendacaoFakeButton>
          </S.RecomendacaoTextBox>
        </S.RecomendacaoBox>
        <S.RecomendacaoBox
          href="http://bit.ly/ved-avenger-tema"
          target="_blank"
          rel="noopener noreferrer"
        >
          <S.RecomendacaoImg
            fluid={imgAvenger.childImageSharp.fluid}
            alt="mockup do tema avanger em notebooks"
          />
          <S.RecomendacaoTextBox>
            <S.RecomendacaoSubtitle>Tema Avenger</S.RecomendacaoSubtitle>
            <S.RecomendacaoText>
              Um tema em wordpress para que vc consiga criar sua própria
              estrutura de blog, com captura de email, links de promoções, e
              muito mais
            </S.RecomendacaoText>
            <S.RecomendacaoFakeButton>Saiba Mais</S.RecomendacaoFakeButton>
          </S.RecomendacaoTextBox>
        </S.RecomendacaoBox>
        <S.RecomendacaoBox
          href="http://bit.ly/ved-rgpd"
          target="_blank"
          rel="noopener noreferrer"
        >
          <S.RecomendacaoImg
            fluid={imgRgpd.childImageSharp.fluid}
            alt="Imagem com título wp rgpd pro plugin do wordpress"
          />
          <S.RecomendacaoTextBox>
            <S.RecomendacaoSubtitle>Plugin RGPD pro</S.RecomendacaoSubtitle>
            <S.RecomendacaoText>
              Plugin RGPD pro para wordpress, configurando seu site para ficar
              de acordo com as leis de proteção de dados e auxiliando nas
              páginas obrigatórias
            </S.RecomendacaoText>
            <S.RecomendacaoFakeButton>Saiba Mais</S.RecomendacaoFakeButton>
          </S.RecomendacaoTextBox>
        </S.RecomendacaoBox>
      </S.RecomendacaoWrapper>
    </Layout>
  )
}

export default RecomendacaoPage
